import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import SettingsHeaderBack from 'app/components/settings/SettingsHeaderBack'
import SubscriptionPlanPrice from 'app/components/settings/SubscriptionPlanPrice'
import SubscriptionPayment from 'app/components/SubscriptionPayment'
import SubscriptionPlans from 'app/components/SubscriptionPlans'
import Box from 'components/Box'
import BoxSection from 'components/BoxSection'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import * as events from 'constants/events'
import paths from 'constants/paths'
import statusCodes from 'constants/status-codes'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import notifications from 'libs/notifications'
import * as subscriptionApiRequest from 'libs/subscription-api-request'
import subscriptions from 'libs/subscriptions'
import * as userApiRequest from 'libs/user-api-request'

export default function SettingsSubscriptionChange() {
  useEventPageVisited(events.SETTINGS_CHANGE_SUBSCRIPTION_PAGE)
  const globalContext = useGlobalContext()
  const [isDisabled, setIsDisabled] = React.useState(!globalContext.user.defaultStripePaymentSource)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [stripeToken, setStripeToken] = React.useState({})

  React.useEffect(() => {
    if (!globalContext.user.defaultStripePaymentSource) return

    async function getPaymentMethod() {
      const response = await subscriptionApiRequest.getPaymentMethodForUser()

      if (response.code === statusCodes.SUCCESS) {
        setStripeToken(response.data?.paymentMethod)
      }
    }
    getPaymentMethod()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalContext.user.defaultStripePaymentSource])

  async function updateSubscription(card?: any) {
    if (!globalContext.user) return

    function handleError(message = 'Something went wrong. Please try again later.') {
      notifications.notifyError(message)

      navigate(paths.SETTINGS, { replace: true })
    }

    const response = await subscriptionApiRequest
      .updateSubscription(globalContext.user.subscription?.id, {
        cancel_at_period_end: false,
        coupon: globalContext.coupon?.id,
        items: [
          {
            plan: globalContext.selectedPlan.id,
            id: globalContext.user.subscription?.items.data[0].id,
          },
        ],
      })
      .catch((error) => {
        handleError(error.message)
      })

    if (response.code === statusCodes.POST_SUCCESS) {
      notifications.notifySuccess('Changed subscription')

      const userUpdated = {
        ...globalContext.user,
        subscription: response.data.subscription,
      }

      if (card) {
        userUpdated.paymentMethod = card
      }

      globalContext.update({ user: userUpdated })
      await globalContext.updateUser()

      const eventData = {
        selectedPlanName: globalContext.selectedPlan?.name,
        amount_paid:
          subscriptions.calculateAmountInCentsWithCoupon(
            globalContext.selectedPlan.amount,
            globalContext.coupon
          ) / 100,
        coupon: globalContext.coupon?.name,
        discount: globalContext.coupon?.percent_off
          ? `${globalContext.coupon?.percent_off}% off`
          : `$${globalContext.coupon?.amount_off} off`,
      }
      // TODO: mv to api
      if (subscriptions.isFreePlan(globalContext.selectedPlan)) {
        globalContext.analytics?.trackEvent(events.SELECT_BASIC_SUBSCRIPTION, eventData)
      }

      if (subscriptions.isMonthlyPlan(globalContext.selectedPlan)) {
        globalContext.analytics?.trackEvent(events.SUBSCRIPTION_PREMIUM_MONTHLY, eventData)
      }

      if (subscriptions.isBiAnnualPlan(globalContext.selectedPlan)) {
        globalContext.analytics?.trackEvent(events.SUBSCRIPTION_PREMIUM_BI_ANNUAL, eventData)
      }

      if (subscriptions.isAnnualPlan(globalContext.selectedPlan)) {
        globalContext.analytics?.trackEvent(events.SUBSCRIPTION_PREMIUM_ANNUAL, eventData)
      }

      navigate(paths.SETTINGS_SUBSCRIPTION_CHANGE_SUCCESS, { replace: true })
    } else {
      handleError()
    }
  }

  async function addCardAndUpdateSubscription(token) {
    const response = await subscriptionApiRequest.addDefaultCardForUser(token.id)

    if (response.code === statusCodes.SUCCESS) {
      updateSubscription(response.data.card)
    }
  }

  async function submit(token = stripeToken) {
    // old comment
    // this is an auto upgraded user trying to pay and is in their trial period
    if (
      token ||
      (!globalContext.paymentMethod &&
        subscriptions.isMonthlyPlan(globalContext.user?.subscription?.plan))
    ) {
      const response = await userApiRequest
        .updateUser({
          //defaultStripePaymentSource: globalContext.stripeToken.id,
          defaultStripePaymentSource: token.id,
        })
        .catch((error) => {
          console.error(error)
          notifications.notifyError()
        })

      if (response.statusCode === statusCodes.POST_SUCCESS) {
        await addCardAndUpdateSubscription(token)
      }
    } else {
      // old comment
      // this is a non-autoupgraded user. This means
      // 1. They were downgraded to a free plan from the upgrade.
      // 2. They never had the upgrade to begin with and were just a bold basic user
      // 3. Either case 1/2 but they then paid for a monthly plan and are upgrading to annual
      if (subscriptions.isFreePlan(globalContext.user?.subscription?.plan)) {
        if (!globalContext.user.exerciseProgramId) {
          await userApiRequest.setUserExerciseProgram()
          await globalContext.updateUser()
        }
        // old comment
        // I need to get out the exerciseProgram to check to see if there is an active program
        // if there is an active program than I simply need to reactivate
        const response = await userApiRequest.updateUser({
          //defaultStripePaymentSource: globalContext.stripeToken.id,
          defaultStripePaymentSource: token.id,
        })
        // old comment
        // we need to attach the card to stripe
        if (response.statusCode === statusCodes.POST_SUCCESS) {
          // old comment
          // we also want to subscribe
          await addCardAndUpdateSubscription(token)
        }
      } else {
        updateSubscription()
      }
    }
  }

  function handlePaymentMethodFormValidityChange(isValid: boolean) {
    setIsDisabled(!isValid)
  }

  function handlePaymentMethodFormSubmit() {
    setIsDisabled(true)
  }

  function handlePaymentMethodFormError() {
    setIsDisabled(false)
    setIsSubmitting(false)
  }

  function handlePaymentMethodFormSuccess(token) {
    submit(token)
  }

  function handleSubmit() {
    setIsSubmitting(true)

    globalContext.analytics?.trackEvent(events.ADD_PAYMENT_INFO, {
      selectedPlanName: globalContext.selectedPlan?.name,
      coupon: globalContext.coupon?.name,
      discount: globalContext.coupon?.percent_off
        ? `${globalContext.coupon?.percent_off}% off`
        : `$${globalContext.coupon?.amount_off} off`,
      amount_paid:
        subscriptions.calculateAmountInCentsWithCoupon(
          globalContext.selectedPlan.amount,
          globalContext.coupon
        ) / 100,
      [events.INTEGRATION.facebook.parameters.CURRENCY]: 'USD',
      [events.INTEGRATION.facebook.parameters.VALUE]: globalContext.selectedPlan.amount / 100,
      [events.INTEGRATION.facebook.parameters.ITEM_IDS]: globalContext.selectedPlan?.id,
      [events.INTEGRATION.facebook.parameters.CONTENT_TYPE]: 'product',
    })

    if (!globalContext.user.defaultStripePaymentSource) {
      // submit will be called from StripeCardForm
      return
    }

    submit()
  }

  const isPlanFreeTrialEligible = subscriptions.isPlanFreeTrialEligible(
    globalContext.selectedPlan,
    globalContext.user?.subscription?.plan
  )

  const paymentMethodFormId = 'stripePaymentForm'

  const hasPaymentMethod = !!globalContext.user.defaultStripePaymentSource

  return (
    <View color="grey" className="SettingsSubscriptionChange">
      <SettingsHeaderBack />
      <Container flush size="large">
        <Box size="xmedium">
          <BoxSection>
            <Row size="small">
              <Text element="h2" size="xlarge" weight="semibold">
                Choose your pay period
              </Text>
            </Row>
            <SubscriptionPlans isPlanFreeTrialEligible={false} />
          </BoxSection>
          {!globalContext.user.defaultStripePaymentSource && (
            <BoxSection>
              <Row size="small">
                <Text element="h2" size="xlarge" weight="semibold">
                  Your information
                </Text>
              </Row>
              <SubscriptionPayment
                formId={paymentMethodFormId}
                onError={handlePaymentMethodFormError}
                onSubmit={handlePaymentMethodFormSubmit}
                onSuccess={handlePaymentMethodFormSuccess}
                onValidityChange={handlePaymentMethodFormValidityChange}
              />
            </BoxSection>
          )}
          {!_.isEmpty(globalContext.selectedPlan) && (
            <BoxSection color="grey" size="small">
              <Row flush={!isPlanFreeTrialEligible} size="small">
                <MediaObject
                  figure={
                    <SubscriptionPlanPrice
                      data={globalContext.selectedPlan.amount / 100}
                      append="USD"
                    />
                  }
                  center
                  figurePosition="right"
                  split>
                  <Text size="xlarge" weight="medium">
                    {subscriptions.isAnnualPlan(globalContext.selectedPlan) ? 'Total' : 'Today’s'}{' '}
                    charge
                  </Text>
                </MediaObject>
              </Row>
              {isPlanFreeTrialEligible && (
                <Text element="p" flush>
                  You will not be charged until the end of your free trial. You can cancel at any
                  time.
                </Text>
              )}
            </BoxSection>
          )}
          <BoxSection>
            <Buttons align="right">
              <Button
                color="green"
                disabled={isDisabled}
                loading={isSubmitting}
                form={paymentMethodFormId}
                type={hasPaymentMethod ? undefined : 'submit'}
                onClick={handleSubmit}>
                Checkout
              </Button>
            </Buttons>
          </BoxSection>
        </Box>
      </Container>
    </View>
  )
}
