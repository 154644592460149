import classNames from 'classnames'
import React from 'react'
import type { RowProps } from 'components/Row'
import type { Component } from 'constants/types'
import styles from './Rows.module.scss'

type Props = {
  children: React.ReactNode
  size?: RowProps['size']
} & Component

export default function Rows({ className, size = 'medium', ...props }: Props) {
  return (
    <div
      {...props}
      className={classNames('Rows', styles.this, styles[`this---${size}`], className)}
    />
  )
}
