import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import LessonBox from 'app/components/LessonBox'
import Button from 'components/Button'
import Container from 'components/Container'
import Head from 'components/Head'
import HeaderSubheader from 'components/HeaderSubheader'
import Loader from 'components/Loader'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import paths from 'constants/paths'
import type { Lesson } from 'constants/types'
import useEventPageVisited from 'hooks/use-event-page-visited'
import { useUserLessonsFavorite } from 'libs/lessons-query'
import styles from './LessonsFavorite.module.scss'

export default function LessonsFavorite() {
  useEventPageVisited('Favorite classes')
  const { data: lessonsFavoriteData, isLoading: isLessonsFavoriteLoading } =
    useUserLessonsFavorite()

  function handleLessonClick(event: any, lessonId: string) {
    const isInsideExploreBox =
      event.target instanceof Element &&
      event.target.closest('.LessonBox') &&
      event.target.innerText !== 'Schedule for later'

    if (isInsideExploreBox) {
      navigate(`${paths.LESSON}${lessonId}`)
    }
  }

  return (
    <div className="Movements">
      <Head bodyAttributes={{ class: 'body---grey body---has-subheader' }} />
      <HeaderSubheader>
        <Text element="h1" size="xlarge" weight="medium">
          My saved classes
        </Text>
      </HeaderSubheader>
      <View color="grey">
        <Container flush size="xxlarge">
          {isLessonsFavoriteLoading ? (
            <Loader overlay={false} />
          ) : _.isEmpty(lessonsFavoriteData.data) ? (
            <>
              <Text align="center" color="grey" className={styles.null}>
                <Row size="small">
                  <Row size="xsmall">
                    <Text color="grey" element="h2" size="xxlarge" weight="medium">
                      Fill this page with your favorite classes
                    </Text>
                  </Row>
                  <Text element="p" flush>
                    Add any Explore or Program class to your favorites to watch later
                  </Text>
                </Row>
                <Button to={paths.PROGRAM} color="white">
                  Go to program
                </Button>
              </Text>
            </>
          ) : (
            <div className={styles.lessons}>
              {lessonsFavoriteData.data?.map((item: Lesson) => (
                <LessonBox
                  data={item}
                  key={item.id}
                  onClick={(event) => handleLessonClick(event, item.id)}
                />
              ))}
            </div>
          )}
        </Container>
      </View>
    </div>
  )
}
