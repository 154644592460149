import classNames from 'classnames'
import { Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import Button from 'components/Button'
import FieldDate from 'components/FieldDate'
import FieldEmail from 'components/FieldEmail'
import FieldFirstName from 'components/FieldFirstName'
import FieldGender from 'components/FieldGender'
import FieldLastName from 'components/FieldLastName'
import FieldPassword from 'components/FieldPassword'
import FieldPhoneNumber from 'components/FieldPhoneNumber'
import FieldPostalCode from 'components/FieldPostalCode'
import Fields from 'components/Fields'
import FieldSmsConsentCheckbox from 'components/FieldSmsConsentCheckbox'
import FormValidationNotification from 'components/FormValidationNotification'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import statusCodes from 'constants/status-codes'
import storage from 'constants/storage'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import { generateFbc } from 'libs/analytics'
import * as cookie from 'libs/cookie'
import enterprise from 'libs/enterprise'
import formatDate from 'libs/format-date'
import logrocket from 'libs/logrocket'
import notifications from 'libs/notifications'
import * as subscriptionApiRequest from 'libs/subscription-api-request'
import subscriptions from 'libs/subscriptions'
import url from 'libs/url'
import user from 'libs/user'
import userApiRequest from 'libs/user-api-request'
import validations from 'libs/validations'

type Props = {
  buttonProps?: {}
  disabled: boolean
  // eslint-disable-next-line no-unused-vars
  onSuccess: (updatedUser: any) => void
} & Component

export default function OnboardingProfileForm({
  buttonProps = {},
  className,
  disabled,
  onSuccess,
}: Props) {
  const globalContext = useGlobalContext()
  const isOctopus = user.isEnterpriseOctopusUser(globalContext.user)
  const [firstName] = React.useState(globalContext.user?.firstName || '')
  const [lastName] = React.useState(globalContext.user?.lastName || '')

  const homeCookie = cookie.getCookie(storage.HOME_KEY)
  const partner = homeCookie || globalContext.partner
  const askForPhone =
    isOctopus || (enterprise.determineReefOrOrca(partner).length > 0 && !user.hasHomeSpecial())

  async function submit(values, formikActions) {
    async function handleError(error) {
      console.error(error)
      notifications.notifyError('Something went wrong. Please try again later.')
      formikActions.setSubmitting(false)

      await globalContext.analytics?.trackEvent(
        events.ONBOARDING_NOT_COMPLETE,
        {
          from: globalContext.user?.loginType,
          source: events.ONBOARDING_PROFILE_PAGE,
        },
        {
          userId: globalContext.user?.id,
        }
      )
    }

    const plans = await subscriptionApiRequest.getPlans()
    const promoCodeValue = cookie.getCookie(storage.PROMO_CODE_KEY)
    let coupon
    if (promoCodeValue && promoCodeValue.length) {
      coupon = subscriptions.findPromoCode(promoCodeValue, plans.data.plans)
    }

    const response = await userApiRequest
      .completeOnboarding({
        ...values,
        coupon,
      })
      .catch((error) => {
        handleError(error)
      })

    if (response.statusCode === statusCodes.POST_SUCCESS) {
      globalContext.update({ user: response.data })
    } else {
      handleError(response)
      return
    }

    if (!user.isOnboardingPaymentRequired(globalContext.user)) {
      const { fbclid } = url.getQueryString()

      await globalContext.analytics?.trackEvent(
        events.ONBOARDING_COMPLETE,
        {
          external_id: globalContext.user?.id, // for fb
          fbc: generateFbc(fbclid),
          from: globalContext.user?.loginType,
          smsConsent: values.smsConsent ? 'true' : 'false',
          firstNameDifferent: values.firstName !== firstName ? 'true' : 'false',
          lastNameDifferent: values.lastName !== lastName ? 'true' : 'false',
        },
        {
          userId: globalContext.user?.id,
        }
      )
    }

    formikActions.setSubmitting(false)

    if (onSuccess) return onSuccess(response.data)
  }

  async function handleSubmit(values, formikActions) {
    if (values.phoneNumber) {
      await globalContext.analytics?.trackEvent(events.PHONE_NUMBER_ADDED)
    }

    values.firstName = values.firstName.trim()
    values.lastName = values.lastName.trim()

    return submit(values, formikActions)
  }

  const fieldProps = {
    ...logrocket.INPUT_PHI_PROPS,
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        dateOfBirth: globalContext.user?.dateOfBirth
          ? formatDate.input(globalContext.user?.dateOfBirth)
          : '',
        email: globalContext.user?.email || '',
        firstName,
        gender: globalContext.user?.gender || '',
        lastName,
        password: '',
        phoneNumber: globalContext.user?.phoneNumber || '',
        postalCode: globalContext.user?.postalCode || '',
        smsConsent: globalContext.user?.smsConsent,
      }}
      validationSchema={Yup.object({
        dateOfBirth: isOctopus ? validations.DATE_OF_BIRTH : undefined,
        email: validations.EMAIL,
        firstName: validations.NAME,
        lastName: validations.NAME,
        password: user.hasPassword(globalContext.user) ? validations.PASSWORD : undefined,
        phoneNumber: validations.PHONE_NUMBER_OPTIONAL,
        postalCode: validations.POSTAL_CODE_OPTIONAL,
      })}
      className={classNames('OnboardingProfileForm', className)}
      onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form>
          <FormValidationNotification />
          {isOctopus && <FieldEmail {...fieldProps} required />}
          <FieldFirstName {...fieldProps} required />
          <FieldLastName {...fieldProps} required />
          {askForPhone && <FieldPhoneNumber {...fieldProps} optional />}
          {isOctopus && (
            <>
              <Fields>
                <FieldDate {...fieldProps} label="Date of Birth" name="dateOfBirth" required />
                <FieldPostalCode {...fieldProps} optional />
              </Fields>
              <FieldGender axis="x" />
            </>
          )}
          {user.hasPassword(globalContext.user) && (
            <FieldPassword
              {...fieldProps}
              autoComplete="new-password"
              label="Create a password"
              required
              showValidationNote
            />
          )}
          {askForPhone && formikProps.values.phoneNumber && (
            <FieldSmsConsentCheckbox shortenedText />
          )}
          <Row size="smallx">
            <Button
              disabled={disabled || formikProps.isSubmitting}
              full
              {...buttonProps}
              onClick={formikProps.submitForm}>
              Continue
            </Button>
          </Row>
          <Text color="ink200" element="p" flush size="xsmall">
            {isOctopus &&
              'We use information about gender and age to help personalize programs for members. '}
            We will not sell or provide your personally identifiable information (e.g. name, email,
            date of birth) to anyone.
          </Text>
        </Form>
      )}
    </Formik>
  )
}
