import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import _ from 'lodash'
import pluralize from 'pluralize'
import React from 'react'
import LessonTags from 'app/components/LessonTags'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Heading2 from 'components/Heading2'
import Link from 'components/Link'
import Loader from 'components/Loader'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Tags from 'components/Tags'
import Text from 'components/Text'
import View from 'components/View'
import paths from 'constants/paths'
import type { Lesson, Playlist } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as challengeApiRequest from 'libs/challenge-api-request'
import { useLatestChallengeForCollectionId } from 'libs/challenge-query'
import * as lessonApiRequest from 'libs/lesson-api-request'
import notifications from 'libs/notifications'
import styles from './Playlist.module.scss'

type Props = {
  playlistId: string
}

export default function PlaylistPage({ playlistId }: Props) {
  const globalContext = useGlobalContext()
  const location = useLocation()
  const [lessons, setLessons] = React.useState<Lesson[]>(location?.state?.lessons)
  const [playlist, setPlaylist] = React.useState<Playlist>(location?.state?.playlist)
  const { data: challengeData, mutate: mutateChallenge } = useLatestChallengeForCollectionId(
    playlistId,
    {}
  )

  React.useEffect(() => {
    if (_.isEmpty(playlist)) return

    globalContext.analytics?.markPageVisited('Collection', {
      title: playlist.title,
    })
  }, [globalContext.analytics, playlist])

  React.useEffect(() => {
    async function getPlaylistAndLessons() {
      if (_.isEmpty(playlist) || _.isEmpty(lessons)) {
        const response = await lessonApiRequest.getPlaylist(playlistId).catch((error: any) => {
          console.error(error)
        })

        setLessons(response.data?.lessons)
        setPlaylist(response.data?.playlist)
      }
    }

    getPlaylistAndLessons()
  }, [playlistId, playlist, lessons])

  function handleLessonClick(lessonId: string) {
    navigate(`${paths.LESSON}${lessonId}#explore`, {
      state: {
        from: location.pathname,
      },
    })
  }

  function handleBackClick() {
    navigate(`${paths.EXPLORE}?showCollections=true`)
  }

  async function handleJoinChallenge() {
    if (challengeData?.data?.challenge?.id) {
      try {
        await challengeApiRequest.joinChallenge(challengeData.data.challenge.id)
        await mutateChallenge()
        notifications.notifySuccess('Successfully joined challenge!')
      } catch {
        notifications.notifyError('Unable to join challenge. Please try again later!')
      }
    }
  }

  const tags = playlist?.tags?.filter((item) => item !== 'default')
  return (
    <div className="Playlist">
      <View color="white" className={styles.header}>
        <Container align="left" flush size="xmedium">
          <Row size="small">
            <Button onClick={handleBackClick} color="white" icon="arrow-left" pill size="small">
              Back
            </Button>
          </Row>
          <Row>
            <Heading2 level={1} levelStyle={4}>
              {playlist?.title}
            </Heading2>
            <Text element="p" flush>
              {playlist?.description}
            </Text>
          </Row>
          <Buttons>
            <Tags
              data={[
                !_.isEmpty(tags) ? tags.join(', ') : undefined,
                pluralize('class', _.size(lessons), true),
              ]}
            />
            {challengeData?.data?.challenge &&
              (!challengeData?.data?.userChallenge ? (
                <Button color="yellow" pill size="small" onClick={handleJoinChallenge}>
                  Join challenge
                </Button>
              ) : (
                <Text element="p" flush>
                  You’re in the challenge!
                </Text>
              ))}
          </Buttons>
        </Container>
      </View>
      <View color="grey">
        <Container flush size="xxlarge">
          {_.isEmpty(lessons) ? (
            <Loader overlay={false} />
          ) : (
            <div className={styles.lessons}>
              {lessons.map((item, index) => (
                <Row flush={index === _.size(lessons) - 1} key={item.id} className={styles.lesson}>
                  <Link
                    className={styles['lesson--box']}
                    onClick={() => handleLessonClick(item.id)}>
                    <div
                      style={{ backgroundImage: `url("${item.videoThumbnail}")` }}
                      className={styles['lesson--image-wrap']}>
                      <LessonTags data={item} className={styles['lesson--tags']} />
                    </div>
                    <MediaObject
                      // TODO
                      figure={<></>}
                      figurePosition="right"
                      className={styles['lesson--box--body2']}>
                      <div className={styles['lesson--box--body']}>
                        <Container align="left" flush size="xmedium">
                          <Row size="small">
                            <Heading2 level={3} levelStyle={4}>
                              {item.videoTitle}
                            </Heading2>
                            <Text element="div">
                              {item.instructorName}
                              {!_.isNil(item.classType) && !_.isEmpty(item.classType[0]) && (
                                <>
                                  {' • '}
                                  {item.classType.join(', ')}
                                </>
                              )}
                            </Text>
                          </Row>
                          <Text element="p" flush className={styles['lesson--description']}>
                            {item.instructions}
                          </Text>
                        </Container>
                      </div>
                    </MediaObject>
                  </Link>
                </Row>
              ))}
            </div>
          )}
        </Container>
      </View>
    </div>
  )
}
