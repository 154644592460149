import classNames from 'classnames'
import { getDay } from 'date-fns'
import _ from 'lodash'
import { DateTime } from 'luxon'
import pluralize from 'pluralize'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Heading2 from 'components/Heading2'
import Link from 'components/Link'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import formatDate from 'libs/format-date'
import styles from './Activity.module.scss'
import { ReactComponent as ArrowUpSvg } from './images/arrow-up.svg'
import { ReactComponent as ClockSvg } from './images/clock.svg'
import { ReactComponent as StarSvg } from './images/star.svg'

type Props = {
  data: {
    allTimeClassesWatched: number
    recentUserStats: []
    streak: number
  }
} & Component

export default function Activity({ className, data }: Props) {
  const globalContext = useGlobalContext()

  if (_.isNil(data) || _.isEmpty(data)) return

  function handleProgamButtonClick() {
    globalContext.analytics?.trackEvent(events.DASHBOARD_ACTIVITY_PROGRAM_BUTTON_CLICK)
  }

  function handleViewAllButtonClick() {
    globalContext.analytics?.trackEvent(events.DASHBOARD_ACTIVITY_VIEW_ALL_BUTTON_CLICK)
  }

  const startOfMonth = DateTime.now().setZone(formatDate.TIMEZONE).startOf('month')
  const activeDaysThisMonthSet = new Set(
    data.recentUserStats
      ?.map((item) => DateTime.fromISO(item.date).setZone(formatDate.TIMEZONE).toISODate()) // convert to YYYY-MM-DD
      .filter((date) => DateTime.fromISO(date).setZone(formatDate.TIMEZONE) >= startOfMonth)
  )
  const daysActiveThisMonth = _.size(activeDaysThisMonthSet)

  const stats = [
    {
      copy: `${data.streak} week streak`,
      icon: StarSvg,
      hide: !data.streak,
    },
    {
      copy: `${data.allTimeClassesWatched} total ${pluralize(
        'class',
        data.allTimeClassesWatched
      )} completed`,
      icon: ArrowUpSvg,
      hide: !data.allTimeClassesWatched,
    },
    {
      copy: `${daysActiveThisMonth} active ${pluralize('day', daysActiveThisMonth)} this month`,
      icon: ClockSvg,
      hide: daysActiveThisMonth === 0,
    },
  ]

  const todayIndex = getDay(new Date())

  const sundayThisWeek = DateTime.now()
    .setZone(formatDate.TIMEZONE)
    .startOf('week')
    .minus({ days: 1 }) // week starts on Monday in luxon but we start on Sunday
  const activeDaysThisWeekSet = new Set(
    data.recentUserStats
      ?.map((item) => DateTime.fromISO(item.date).setZone(formatDate.TIMEZONE).toISODate()) // convert to YYYY-MM-DD to dedup by day
      .filter((date) => DateTime.fromISO(date).setZone(formatDate.TIMEZONE) >= sundayThisWeek)
  )

  const daysOfWeekActive: number[] = []
  activeDaysThisWeekSet.forEach((dateString) => {
    daysOfWeekActive.push(DateTime.fromISO(dateString).setZone(formatDate.TIMEZONE).weekday % 7)
  })

  return (
    <Box color="white" className={classNames('Activity', styles.this, className)}>
      <Row size="xsmall">
        <MediaObject
          figure={
            <Link to={paths.ACTIVITY} onClick={handleViewAllButtonClick}>
              <Text weight="medium">View all</Text>
            </Link>
          }
          center
          figurePosition="right">
          <Heading2 flush level={3} levelStyle={5}>
            My activity
          </Heading2>
        </MediaObject>
      </Row>
      {(data.allTimeClassesWatched === 0 || _.isEmpty(activeDaysThisWeekSet)) && (
        <>
          <Row size="xxxsmall">
            <Text element="p" flush>
              {data.allTimeClassesWatched === 0
                ? `Take a Bold class and see your activity here.`
                : `Take a class this week to increment your streak!`}
            </Text>
          </Row>
          <Button to={paths.PROGRAM} level="text" onClick={handleProgamButtonClick}>
            Start class →
          </Button>
        </>
      )}
      {!_.isEmpty(daysOfWeekActive) && (
        <>
          <Row size="xsmall">
            <Text element="p" flush size="small" weight="semibold">
              {_.size(daysOfWeekActive)} active {pluralize('day', _.size(daysOfWeekActive))} this
              week, keep it up!
            </Text>
          </Row>
          <Text casing="upper" color="white" element="div" weight="medium" className={styles.week}>
            {formatDate.DAYS_OF_WEEK_XSMALL.map((item: string, index: number) => (
              <div
                key={item}
                className={classNames(
                  styles['week--day'],
                  daysOfWeekActive?.includes(index) && styles['week--day---active'],
                  index === todayIndex && styles['week--day---today']
                )}>
                {item}
              </div>
            ))}
          </Text>
        </>
      )}
      {(!stats[0].hide || !stats[1].hide || !stats[2].hide) && (
        <>
          <hr className={styles.divider} />
          <Text color="purple" element="div" weight="medium" className={styles.box}>
            {stats
              .filter((item) => !item.hide)
              .map((item) => (
                <Row key={item.copy} size="xsmall">
                  <MediaObject figure={<item.icon />} center size="xxsmall">
                    {item.copy}
                  </MediaObject>
                </Row>
              ))}
          </Text>
        </>
      )}
    </Box>
  )
}
