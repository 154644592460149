import { FieldArray, Form, Formik } from 'formik'
import _ from 'lodash'
import React from 'react'
import * as Yup from 'yup'
import * as adminApiRequest from 'admin/libs/admin-api-request'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Divider from 'components/Divider'
import Field2 from 'components/Field2'
import FieldCheckboxButtons from 'components/FieldCheckboxButtons'
import FieldRadioButtons from 'components/FieldRadioButtons'
import Label from 'components/Label'
import Modal from 'components/Modal'
import ModalBox from 'components/ModalBox'
import notifications from 'libs/notifications'
import validations from 'libs/validations'

type Props = {
  isOpen: boolean
  onClose: () => void
  propsLessonRegimen: any
}

export default function AdminLessonRegimenUpdateModal({
  isOpen,
  onClose,
  propsLessonRegimen,
  ...props
}: Props) {
  const [numberLiveScheduleSets, setNumberLiveScheduleSets] = React.useState(0)
  const [numberRerunScheduleSets, setNumberRerunScheduleSets] = React.useState(0)
  const intensityMapping = {
    beginner: 2,
    intermediate: 4,
    advanced: 8,
  }
  const getIntensityLabel = (averageIntensity: number | null) => {
    if (_.isNil(averageIntensity)) return ''
    if (averageIntensity > 0 && averageIntensity <= 3) return 'beginner'
    if (averageIntensity >= 4 && averageIntensity < 7) return 'intermediate'
    if (averageIntensity >= 7 && averageIntensity <= 10) return 'advanced'
    return ''
  }
  const [initialValues, setInitialValues] = React.useState({
    [`0_liveDaysOfWeek`]: [],
    [`1_liveDaysOfWeek`]: [],
    [`2_liveDaysOfWeek`]: [],
    [`3_liveDaysOfWeek`]: [],
    [`4_liveDaysOfWeek`]: [],
    [`0_rerunDaysOfWeek`]: [],
    [`1_rerunDaysOfWeek`]: [],
    [`2_rerunDaysOfWeek`]: [],
    [`3_rerunDaysOfWeek`]: [],
    [`4_rerunDaysOfWeek`]: [],
    tags: '',
    equipment: '',
    isActive: true,
    expectedDurationMins: 45,
    lessonThumbnails: [''],
    averageIntensity: '',
  })

  React.useEffect(() => {
    if (!_.isNil(propsLessonRegimen)) {
      const liveSchedulesForForm: any = {
        [`0_liveDaysOfWeek`]: [],
        [`1_liveDaysOfWeek`]: [],
        [`2_liveDaysOfWeek`]: [],
        [`3_liveDaysOfWeek`]: [],
        [`4_liveDaysOfWeek`]: [],
      }
      propsLessonRegimen.liveSchedule.forEach((schedule, index) => {
        liveSchedulesForForm[`${index}_liveDaysOfWeek`] = schedule[0]
        liveSchedulesForForm[`${index}_liveTimePT`] = schedule[1]
      })
      const rerunSchedulesForForm: any = {
        [`0_rerunDaysOfWeek`]: [],
        [`1_rerunDaysOfWeek`]: [],
        [`2_rerunDaysOfWeek`]: [],
        [`3_rerunDaysOfWeek`]: [],
        [`4_rerunDaysOfWeek`]: [],
      }
      propsLessonRegimen.rerunSchedule.forEach((schedule, index) => {
        rerunSchedulesForForm[`${index}_rerunDaysOfWeek`] = schedule[0]
        rerunSchedulesForForm[`${index}_rerunTimePT`] = schedule[1]
      })
      setInitialValues({
        ...propsLessonRegimen,
        ...liveSchedulesForForm,
        ...rerunSchedulesForForm,
        tags: propsLessonRegimen.tags.join(', '),
        equipment: propsLessonRegimen.equipment.join(', '),
        averageIntensity: getIntensityLabel(propsLessonRegimen?.averageIntensity),
      })

      setNumberLiveScheduleSets(propsLessonRegimen.liveSchedule.length)
      setNumberRerunScheduleSets(propsLessonRegimen.rerunSchedule.length)
    }
  }, [propsLessonRegimen])

  async function handleSubmit(values) {
    const tags = !_.isNil(values.tags)
      ? values.tags.split(',').map((tag) => _.capitalize(tag.trim()))
      : []
    const equipment = !_.isNil(values.equipment)
      ? values.equipment.split(',').map((item) => _.capitalize(item.trim()))
      : []
    const liveSchedule = []
    for (let index = 0; index < numberLiveScheduleSets; index++) {
      const daysOfWeekForSet = values[`${index}_liveDaysOfWeek`]
      const timeForSet = values[`${index}_liveTimePT`]
      if (!_.isEmpty(daysOfWeekForSet) && !_.isNil(timeForSet)) {
        liveSchedule.push([daysOfWeekForSet, timeForSet])
      }
    }
    const rerunSchedule = []
    for (let index = 0; index < numberRerunScheduleSets; index++) {
      const daysOfWeekForSet = values[`${index}_rerunDaysOfWeek`]
      const timeForSet = values[`${index}_rerunTimePT`]
      if (!_.isEmpty(daysOfWeekForSet) && !_.isNil(timeForSet)) {
        rerunSchedule.push([daysOfWeekForSet, timeForSet])
      }
    }

    const averageIntensity = intensityMapping[values.averageIntensity] || null

    const dataToSave = {
      title: values.title,
      description: values.description,
      expectedDurationMins: values.expectedDurationMins,
      instructorName: values.instructorName,
      instructorEmail: values.instructorEmail,
      lessonThumbnails: values.lessonThumbnails,
      position: values.position,
      autoAddToExplore: values.autoAddToExplore,
      isActive: values.isActive,
      tags,
      equipment,
      liveSchedule,
      rerunSchedule,
      averageIntensity,
    }
    if (propsLessonRegimen?.id) {
      const updateResult = await adminApiRequest.updateLessonRegimen(
        propsLessonRegimen.id,
        dataToSave
      )
      if (updateResult.statusCode === 200) {
        notifications.notifySuccess('Successfully updated lesson regimen!')
        if (onClose) onClose()
      } else {
        notifications.notifyError('Ruh roh something went wrong')
      }
    } else {
      const createResult = await adminApiRequest.createLessonRegimen(dataToSave)
      if (createResult.statusCode === 200) {
        notifications.notifySuccess('Successfully created new lesson regimen!')
        if (onClose) onClose()
      } else {
        notifications.notifyError('Ruh roh something went wrong')
      }
    }
  }

  function handleAddLiveScheduleSet() {
    setNumberLiveScheduleSets(numberLiveScheduleSets + 1)
    setInitialValues({
      ...initialValues,
      [`${numberLiveScheduleSets}_liveDaysOfWeek`]: [],
    })
  }

  function handleAddRerunScheduleSet() {
    setNumberRerunScheduleSets(numberRerunScheduleSets + 1)
    setInitialValues({
      ...initialValues,
      [`${numberRerunScheduleSets}_rerunDaysOfWeek`]: [],
    })
  }

  const intensityOptions = [
    {
      text: 'Beginner',
      value: 'beginner',
    },
    {
      text: 'Intermediate',
      value: 'intermediate',
    },
    {
      text: 'Advanced',
      value: 'advanced',
    },
  ]

  const dayOptions = [
    {
      text: 'Monday',
      value: 'Mon',
    },
    {
      text: 'Tuesday',
      value: 'Tue',
    },
    {
      text: 'Wednesday',
      value: 'Wed',
    },
    {
      text: 'Thursday',
      value: 'Thu',
    },
    {
      text: 'Friday',
      value: 'Fri',
    },
    {
      text: 'Saturday',
      value: 'Sat',
    },
    {
      text: 'Sunday',
      value: 'Sun',
    },
  ]

  const positionOptions = [
    {
      text: 'Seated',
      value: 'sitting',
    },
    {
      text: 'Sitting/Standing',
      value: 'sitting/standing',
    },
    {
      text: 'Standing',
      value: 'standing',
    },
    {
      text: 'Standing and floor',
      value: 'standing/floor',
    },
  ]

  const trueFalse = [
    {
      text: 'True',
      value: true,
    },
    {
      text: 'False',
      value: false,
    },
  ]

  return (
    <Modal size="xxlarge" {...props} isOpen={isOpen} onRequestClose={onClose}>
      <ModalBox size="small">
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            title: validations.REQUIRED,
            instructorName: validations.REQUIRED,
            instructorEmail: validations.EMAIL,
            lessonThumbnails: validations.REQUIRED_ARRAY,
          })}
          onSubmit={handleSubmit}>
          {(formikProps) => (
            <Form>
              <Field2 label="Title" required name="title" />
              <Field2 label="Description" required name="description" />
              <Field2 label="Instructor name" required name="instructorName" />
              <Field2 label="Instructor email" required name="instructorEmail" />
              <Label htmlFor="lessonThumbnails">
                <b>Lesson Thumbnail URLs</b>
              </Label>
              <FieldArray name="lessonThumbnails">
                {({ push, remove }) => (
                  <div>
                    {formikProps.values.lessonThumbnails.map((blank, index) => (
                      <Buttons key={index}>
                        <Field2 name={`lessonThumbnails.${index}`} />
                        <Button color="white" onClick={() => remove(index)}>
                          Remove
                        </Button>
                      </Buttons>
                    ))}
                    <Button onClick={() => push('')}>Add Thumbnails</Button>
                  </div>
                )}
              </FieldArray>
              <FieldRadioButtons
                size="small"
                key="position"
                label="Position"
                name="position"
                options={positionOptions}
              />
              <FieldRadioButtons
                size="small"
                key="intensity"
                label="Intensity"
                name="averageIntensity"
                options={intensityOptions}
              />
              <Field2 label="Required equipment (comma separated)" name="equipment" />
              <Field2
                label="How long are these classes usually? (enter a number in minutes)"
                name="expectedDurationMins"
              />
              <Field2 label="Class types (comma separated)" name="tags" />
              <Divider thin />
              {Array.from({ length: numberLiveScheduleSets }).map((thing, index) => (
                <div key={`live_${index}_div`}>
                  <FieldCheckboxButtons
                    label={`(${index + 1}) Live class days of week`}
                    name={`${index}_liveDaysOfWeek`}
                    axis="x"
                    key={`${index}_liveDaysOfWeek`}
                    options={dayOptions}
                    size="small"
                  />
                  <Field2
                    label={`(${
                      index + 1
                    }) Live class time (PT) (eg 08:00 for 8AM PT, 15:00 for 3PM PT)`}
                    name={`${index}_liveTimePT`}
                  />
                </div>
              ))}
              {numberLiveScheduleSets < 5 && (
                <Button color="white" onClick={handleAddLiveScheduleSet}>
                  Add Live Schedule
                </Button>
              )}
              <Divider thin />
              {Array.from({ length: numberRerunScheduleSets }).map((thing, index) => (
                <>
                  <FieldCheckboxButtons
                    label={`(${index + 1}) Rerun class days of week`}
                    name={`${index}_rerunDaysOfWeek`}
                    axis="x"
                    key={`${index}_rerunDaysOfWeek`}
                    options={dayOptions}
                    size="small"
                  />
                  <Field2
                    label={`(${
                      index + 1
                    }) Rerun class time (PT) (eg 08:00 for 8AM PT, 15:00 for 3PM PT)`}
                    name={`${index}_rerunTimePT`}
                  />
                </>
              ))}
              {numberRerunScheduleSets < 5 && (
                <Button color="white" onClick={handleAddRerunScheduleSet}>
                  Add Rerun Schedule
                </Button>
              )}
              <Divider thin />
              <FieldRadioButtons
                size="small"
                key="isActive"
                label="Active?"
                name="isActive"
                options={trueFalse}
              />
              <Button disabled={formikProps.isSubmitting} onClick={formikProps.submitForm}>
                Update data
              </Button>
            </Form>
          )}
        </Formik>
      </ModalBox>
    </Modal>
  )
}
