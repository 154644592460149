import { datadogLogs } from '@datadog/browser-logs'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { Router, useLocation } from '@reach/router'
import { SkipNavContent, SkipNavLink } from '@reach/skip-nav'
import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import * as rdd from 'react-device-detect'
import { StripeProvider } from 'react-stripe-elements'
import AdminBoldlessEnterpriseMembers from 'admin/pages/AdminBoldlessEnterpriseMembers'
import AdminCollectionCreate from 'admin/pages/AdminCollectionCreate'
import AdminEnterprise from 'admin/pages/AdminEnterprise'
import AdminLanding from 'admin/pages/AdminLanding'
import AdminLessonRegimens from 'admin/pages/AdminLessonRegimens'
import AdminLessons from 'admin/pages/AdminLessons'
import AdminLessonStreams from 'admin/pages/AdminLessonStreams'
import AdminMarketing from 'admin/pages/AdminMarketing'
import AdminUsers from 'admin/pages/AdminUsers'
import TrainerDashboard from 'admin/pages/TrainerDashboard'
import FirstProgramLessonReminderModal from 'app/components/FirstProgramLessonReminderModal'
import NpsModal from 'app/components/NpsModal'
import Program from 'app/components/Program'
import SubscriptionTrialExpirationBanner from 'app/components/SubscriptionTrialExpirationBanner'
import Activity from 'app/pages/Activity'
import Badges from 'app/pages/Badges'
import Explore from 'app/pages/Explore'
import Landing from 'app/pages/Landing'
import Lesson from 'app/pages/Lesson'
import LessonsFavorite from 'app/pages/LessonsFavorite'
import Live from 'app/pages/Live'
import LiveLessonFeedback from 'app/pages/LiveLessonFeedback'
import LiveRestreamLesson from 'app/pages/LiveRestreamLesson'
import Movement from 'app/pages/Movement'
import Movements from 'app/pages/Movements'
import Playlist from 'app/pages/Playlist'
import ProgramBuild from 'app/pages/ProgramBuild'
import ProgramChange from 'app/pages/ProgramChange'
import ProgramFirstPostLesson from 'app/pages/ProgramFirstPostLesson'
import ProgramFirstPostLessonFeedback from 'app/pages/ProgramFirstPostLessonFeedback'
import Referrals from 'app/pages/Referrals'
import Settings from 'app/pages/Settings'
import SettingsInvoices from 'app/pages/SettingsInvoices'
import SettingsSubscription from 'app/pages/SettingsSubscription'
import SettingsSubscriptionChange from 'app/pages/SettingsSubscriptionChange'
import SettingsSubscriptionChangeSuccess from 'app/pages/SettingsSubscriptionChangeSuccess'
import Unsubscribe from 'app/pages/Unsubscribe'
import Assessments from 'assessments/pages/Assessments'
import ErrorBoundary from 'components/ErrorBoundary'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { Route, RoutePrivate } from 'components/Route'
import experiments from 'constants/experiments'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import EnterpriseEligible from 'enterprise/components/EnterpriseEligible'
import EnterpriseReefOrcaCheckinModal from 'enterprise/reef-orca/components/EnterpriseReefOrcaCheckinModal'
import environment from 'libs/environment'
import segment from 'libs/segment'
import user from 'libs/user'
import OnboardingAssessmentResults from 'onboarding/pages/OnboardingAssessmentResults'
import OnboardingEnterpriseEligibility from 'onboarding/pages/OnboardingEnterpriseEligibility'
import OnboardingEnterpriseEligibilityRecheck from 'onboarding/pages/OnboardingEnterpriseEligibilityRecheck'
import OnboardingEnterpriseReefCheckLoader from 'onboarding/pages/OnboardingEnterpriseReefCheckLoader'
import OnboardingIntro from 'onboarding/pages/OnboardingIntro'
import OnboardingPayment from 'onboarding/pages/OnboardingPayment'
import OnboardingProfile from 'onboarding/pages/OnboardingProfile'
import OnboardingProgramBuild from 'onboarding/pages/OnboardingProgramBuild'
import OnboardingProgramIntroduction from 'onboarding/pages/OnboardingProgramIntroduction'
import OnboardingQuestions from 'onboarding/pages/OnboardingQuestions'
import '@reach/skip-nav/styles.css'
import styles from './App.module.scss'

const growthbook = new GrowthBook({
  ...experiments.GROWTHBOOK_SETTINGS,
  trackingCallback: (experiment, result) => {
    segment.track('Experiment Viewed', {
      'Experiment Id': experiment.key,
      'Variant Id': result.variationId,
      'Variation Value': result.value,
      '$source': 'growthbook',
    })
  },
})
growthbook.init({ timeout: 2000 })

export default function App() {
  const globalContext = useGlobalContext()
  const location = useLocation()
  const [stripe, setStripe] = React.useState(null)

  React.useEffect(() => {
    async function checkOBStatus() {
      if (!globalContext.user) return

      if (
        !globalContext.user.isOnboardingCompleted &&
        !paths.ONBOARDING_PATHS.includes(location.pathname)
      ) {
        navigate(paths.ONBOARDING_QUESTIONS)
      }
    }
    checkOBStatus()
  }, [globalContext.analytics, globalContext.user, location.pathname])

  React.useEffect(() => {
    if (globalContext.isEnterprise) return

    const stripeScript = document.createElement('script')
    stripeScript.setAttribute('id', 'stripe-js')

    document.body.append(stripeScript)

    stripeScript.addEventListener('load', () => {
      // TODO: Put into AWS Secrets Manager
      // Create Stripe instance once Stripe.js loads
      setStripe(
        window.Stripe(
          environment.isProduction
            ? 'pk_live_dqOHAXJqGuoKlqhhWdwzTC1w0097TiyBdl'
            : 'pk_test_d9rIuMZxCsv0bft7yHF7y3rX00Bc1V22Tk'
        )
      )
    })

    stripeScript.src = 'https://js.stripe.com/v3/'
  }, [globalContext.isEnterprise])

  const userId = globalContext?.user?.id

  React.useEffect(() => {
    if (_.isNil(userId)) return

    datadogLogs.setGlobalContextProperty('userId', userId)

    growthbook.setAttributes({
      ...growthbook.getAttributes(), // preserve existing attributes
      browser: rdd.browserName,
      deviceType: rdd.deviceType,
      fullBrowserVersion: rdd.fullBrowserVersion,
      id: userId, // || window.analytics?.user().anonymousId(),
      isProductionEnv: environment.isProduction,
      os: rdd.osName,
      url: window.location.href,
    })
  }, [userId])

  const isAdminUser = user.isAdminUser(globalContext.user)
  const isTrainerUser = user.isTrainerUser(globalContext.user)

  return (
    <ErrorBoundary>
      <GrowthBookProvider growthbook={growthbook}>
        <StripeProvider stripe={stripe}>
          <div className={classNames('App', styles.this)}>
            {/* not wrapping caused really odd behavior at /app/live/ on refresh _only_ on build */}
            <div>
              <SkipNavLink />
              <Header
                banner={<SubscriptionTrialExpirationBanner />}
                hideForPaths={[paths.ACTIVITY, paths.EXPLORE, paths.LIVE_LESSONS, paths.SETTINGS]}
              />
            </div>
            <SkipNavContent />
            {globalContext.user?.isOnboardingCompleted && <FirstProgramLessonReminderModal />}
            {globalContext.user?.isOnboardingCompleted && <NpsModal />}
            {globalContext.user?.isOnboardingCompleted && <EnterpriseReefOrcaCheckinModal />}
            <div className={styles.main}>
              {/* prettier-ignore */}
              <Router className={styles.router}>
                <RoutePrivate path={paths.HOME_AUTHED} component={Landing} />
                <RoutePrivate path={paths.ACTIVITY} component={Activity} />
                <RoutePrivate path={paths.ASSESSMENTS_AUTHED} component={Assessments} />
                <RoutePrivate path={paths.LIVE_LESSON_FEEDBACK} component={LiveLessonFeedback} />
                <RoutePrivate path={paths.BADGES} component={Badges} />
                <RoutePrivate path={paths.ENTERPRISE_ELIGIBLE} component={EnterpriseEligible} />
                <RoutePrivate path={paths.EXPLORE} component={Explore} />
                <RoutePrivate path={`${paths.LESSON}:lessonId`} component={Lesson} />
                <RoutePrivate path={paths.LESSONS_FAVORITE} component={LessonsFavorite} />
                <RoutePrivate path={paths.LIVE_LESSONS} component={Live} />
                <RoutePrivate path={`${paths.LIVE_LESSONS}:lessonId`} component={Live} />
                <RoutePrivate path={`${paths.LIVE_RESTREAM_LESSON}:lessonStreamId`} component={LiveRestreamLesson} />
                <RoutePrivate path={paths.MOVEMENTS} component={Movements} />
                <RoutePrivate path={`${paths.MOVEMENTS}:movementId`} component={Movement} />
                {/* TODO: rm when api/emails updated */}
                <RoutePrivate path={`${paths.LIVE_LESSONS}sign-up/:lessonId`} component={Live} />
                <RoutePrivate path={paths.ONBOARDING_ASSESSMENT_RESULTS} component={OnboardingAssessmentResults} />
                <RoutePrivate path={paths.ONBOARDING_ENTERPRISE_ELIGIBILITY} component={OnboardingEnterpriseEligibility} />
                <RoutePrivate path={paths.ONBOARDING_ENTERPRISE_ELIGIBILITY_RECHECK} component={OnboardingEnterpriseEligibilityRecheck} />
                <RoutePrivate path={paths.ONBOARDING_ENTERPRISE_REEF_CHECK_LOADER} component={OnboardingEnterpriseReefCheckLoader} />
                <RoutePrivate path={paths.ONBOARDING_INTRO} component={OnboardingIntro} />
                <RoutePrivate path={paths.ONBOARDING_PAYMENT} component={OnboardingPayment} />
                <RoutePrivate path={paths.ONBOARDING_PROFILE} component={OnboardingProfile} />
                <RoutePrivate path={paths.ONBOARDING_QUESTIONS} component={OnboardingQuestions} />
                <RoutePrivate path={paths.ONBOARDING_PROGRAM_BUILD} component={OnboardingProgramBuild} />
                <RoutePrivate path={paths.ONBOARDING_PROGRAM_INTRODUCTION} component={OnboardingProgramIntroduction} />
                <RoutePrivate path={`${paths.PLAYLIST}:playlistId`} component={Playlist} />
                <RoutePrivate path={paths.PROGRAM} component={Program} />
                <RoutePrivate path={paths.PROGRAM_BUILD} component={ProgramBuild} />
                <RoutePrivate path={paths.PROGRAM_CHANGE} component={ProgramChange} />
                <RoutePrivate path={paths.PROGRAM_FIRST_POST_LESSON} component={ProgramFirstPostLesson} />
                <RoutePrivate path={paths.PROGRAM_FIRST_POST_LESSON_FEEDBACK} component={ProgramFirstPostLessonFeedback} />
                <RoutePrivate path={paths.REFERRALS} component={Referrals} />
                <RoutePrivate path={paths.SETTINGS} component={Settings} />
                <RoutePrivate path={paths.SETTINGS_INVOICES} component={SettingsInvoices} />
                <RoutePrivate path={paths.SETTINGS_SUBSCRIPTION} component={SettingsSubscription} />
                <RoutePrivate path={paths.SETTINGS_SUBSCRIPTION_CHANGE} component={SettingsSubscriptionChange} />
                <RoutePrivate path={paths.SETTINGS_SUBSCRIPTION_CHANGE_SUCCESS} component={SettingsSubscriptionChangeSuccess} />

                <Route path={`${paths.UNSUBSCRIBE}:unsubscribeId`} component={Unsubscribe} />

                {isAdminUser && <Route path={paths.ADMIN} component={AdminLanding} />}
                {isAdminUser && <Route path={paths.ADMIN_BOLDLESS_ENTERPRISE_MEMBERS} component={AdminBoldlessEnterpriseMembers} />}
                {isAdminUser && <Route path={paths.ADMIN_COLLECTION_CREATE} component={AdminCollectionCreate} />}
                {isAdminUser && <Route path={paths.ADMIN_ENTERPRISE} component={AdminEnterprise} />}
                {isAdminUser && <Route path={paths.ADMIN_LESSONS} component={AdminLessons} />}
                {isAdminUser && <Route path={paths.ADMIN_LESSON_REGIMENS} component={AdminLessonRegimens} />}
                {isAdminUser && <Route path={paths.ADMIN_LESSON_STREAMS} component={AdminLessonStreams} />}
                {isAdminUser && <Route path={paths.ADMIN_USERS} component={AdminUsers} />}
                {isAdminUser && <Route path={paths.ADMIN_MARKETING_REPORT} component={AdminMarketing} />}
                {isAdminUser && <Route path={`${paths.ADMIN_USERS}:id`} component={AdminUsers} />}

                {isTrainerUser && <Route path={paths.TRAINER} component={TrainerDashboard} />}
              </Router>
            </div>
            {!(isAdminUser || isTrainerUser) && (
              <Footer hideForPaths={[paths.PROGRAM_BUILD, paths.PROGRAM_CHANGE]} />
            )}
          </div>
        </StripeProvider>
      </GrowthBookProvider>
    </ErrorBoundary>
  )
}
