import classNames from 'classnames'
import React from 'react'
import Media from 'react-media'
import Box from 'components/Box'
import Button, { type ButtonProps } from 'components/Button'
import Container from 'components/Container'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import constants from 'styles/constants.module.scss'
import styles from './Announcement.module.scss'
import { ReactComponent as SparkleSvg } from './images/sparkle.svg'

type Props = {
  buttonProps: Partial<ButtonProps>
  children: React.ReactNode
  section?: boolean
  title: string
} & Component

export default function Announcement({
  buttonProps = {},
  children,
  className,
  section,
  title,
}: Props) {
  const button = <Button color="white" {...buttonProps} />

  return (
    <Box
      color="purple100"
      size="small"
      className={classNames(
        'Announcement',
        styles.this,
        section && styles['this---section'],
        className
      )}>
      <Container flush size="xxlarge">
        <Text color="black" element="div">
          <MediaObject
            figure={<Media query={{ minWidth: constants.GT_TABLET }}>{button}</Media>}
            figurePosition="right"
            reset="tablet">
            <MediaObject figure={<SparkleSvg aria-hidden />} size="small">
              <Row size="xxxsmall">
                <Text element="h3" size="large" weight="medium">
                  {title}
                </Text>
              </Row>
              <Text element="p" flush>
                {children}
              </Text>
              <Media query={{ maxWidth: constants.TABLET }}>
                <div className={styles.button}>{button}</div>
              </Media>
            </MediaObject>
          </MediaObject>
        </Text>
      </Container>
    </Box>
  )
}
