import classNames from 'classnames'
import _ from 'lodash'
import { DateTime } from 'luxon'
import pluralize from 'pluralize'
import React from 'react'
import * as rdd from 'react-device-detect'
import GoalAndRemindersModalBox from 'app/components/GoalAndRemindersModalBox'
import Box from 'components/Box'
import ButtonWithModal from 'components/ButtonWithModal'
import Confetti from 'components/Confetti'
import Link from 'components/Link'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as cookie from 'libs/cookie'
import formatDate from 'libs/format-date'
import { useProgramCurrentWeek } from 'libs/program-query'
import url from 'libs/url'
import user from 'libs/user'
import { useUserGoal } from 'libs/user-query'
import constants from 'styles/constants.module.scss'
import styles from './ProgramGoal.module.scss'
import ProgramGoalModal from './components/ProgramGoalModal'
import ProgramGoalWeek from './components/ProgramGoalWeek'
import { ReactComponent as LightningSvg } from './images/lightning.svg'
import { ReactComponent as PencilSvg } from './images/pencil.svg'

type Props = {
  confetti?: boolean
  nudgeGoalModal?: boolean
} & Component

export default function ProgramGoal({ className, confetti, nudgeGoalModal }: Props) {
  const globalContext = useGlobalContext()
  const { data: programCurrentWeekData } = useProgramCurrentWeek()
  const { data: userGoalData } = useUserGoal()
  const [showGoalModal, setShowGoalModal] = React.useState<boolean | undefined>()

  const { setGoal } = url.getQueryString()

  const [showGoalAndRemindersModal, setShowGoalAndRemindersModal] = React.useState<boolean>(
    setGoal === 'true' ?? false
  )
  if (setGoal) {
    url.setUrlWithoutQueryStringKeyValue('setGoal')
  }

  const lessons = programCurrentWeekData?.data?.recentGeneralSessions
  const goal = userGoalData?.data?.goalExerciseFrequency

  const sundayThisWeek = DateTime.now()
    .setZone(formatDate.TIMEZONE)
    .startOf('week')
    .minus({ days: 1 }) // week starts on Monday in luxon but we start on Sunday

  const daysActive = _.size(user.getUniqueDaysActiveThisWeek(lessons))
  const hasDaysActive = daysActive > 0
  const isGoalMet = daysActive === goal
  const isGoalExceeded = daysActive > goal

  const initialGoalCookie = 'initial-goal-setting'

  const showSooner = rdd.isMobile || rdd.isAndroid
  const REMIND_TIME = nudgeGoalModal
    ? 10_000 // 10 sec after 1st or 2nd lesson video has ended
    : showSooner
      ? 20_000
      : 120_000 // 20 sec for mobile, otherwise 2 mins after inactivity on program page

  React.useEffect(() => {
    if (!goal && !globalContext.isVideoPlaying && !cookie.getCookie(initialGoalCookie)) {
      const timer = setTimeout(() => {
        setShowGoalAndRemindersModal(true)
      }, REMIND_TIME)

      return () => clearTimeout(timer)
    }
  }, [globalContext.isVideoPlaying, goal, REMIND_TIME])

  React.useEffect(() => {
    const progamGoalModalCookieKey = 'program-goal-modal'
    const sundayThisWeekString = sundayThisWeek.toString()

    if (isGoalMet && cookie.getCookie(progamGoalModalCookieKey) !== sundayThisWeekString) {
      setShowGoalModal(true)
      cookie.createCookie(progamGoalModalCookieKey, sundayThisWeekString)
    }
  }, [daysActive, goal, isGoalMet, sundayThisWeek])

  function handleSuccess() {
    cookie.createCookie(initialGoalCookie, 'true')
    setShowGoalAndRemindersModal(false)
    globalContext.analytics?.trackEvent('Goal and reminders set')
  }

  function handleClose() {
    cookie.createCookie(initialGoalCookie, 'true')
    globalContext.analytics?.trackEvent('Goal and reminders modal closed')
  }

  function handleEditSuccess(reminderDays: any, successful: boolean) {
    setShowGoalAndRemindersModal(false)

    if (successful) {
      const event = reminderDays[0] !== 'None' ? 'edited' : 'removed'
      globalContext.analytics?.trackEvent(`Goal and reminders ${event}`)
    }
  }

  function handleGoalAndRemindersModalButtonClick() {
    setShowGoalAndRemindersModal(true)
  }

  const commonProps = {
    className: classNames('ProgramGoal', styles.this, className),
  }

  if (!goal) {
    return (
      <Box {...commonProps}>
        <Row size="xxxsmall" aria-hidden>
          <LightningSvg />
        </Row>
        <Text element="p">
          Get started off on the right foot by setting up your goal and reminders.
        </Text>
        <ButtonWithModal /* opens automatically after inactivity for 20 sec for mobile or 2 min otherwise */
          full
          modal={<GoalAndRemindersModalBox onSuccess={handleSuccess} />}
          showModal={showGoalAndRemindersModal}
          onClick={handleGoalAndRemindersModalButtonClick}
          onClose={handleClose}>
          Setup goal and reminders
        </ButtonWithModal>
      </Box>
    )
  }

  const isWeekComplete = daysActive === _.size(formatDate.DAYS_OF_WEEK)

  let copy: React.ReactElement | string =
    `Start your week off right by taking a class today! Your goal is to exercise ${pluralize(
      'day',
      goal,
      true
    )} this week.`

  if (hasDaysActive) {
    copy = `You’ve exercised ${pluralize(
      'day',
      daysActive,
      true
    )} this week. You’re on your way to reach your goal to exercise ${pluralize(
      'day',
      goal,
      true
    )} this week.`
  }

  if (isGoalMet) {
    copy =
      'Congratulations! You met your weekly goal. Ready for a bonus round? Jump back into your program.'
  }

  if (isGoalExceeded) {
    copy = 'You already crushed your goal this week, but don’t stop now!'
  }

  if (isWeekComplete) {
    const hasExplore = user.hasExplore(globalContext.user)
    const hasLiveLessons = user.hasLiveLessons(globalContext.user)

    const liveLessonsLink = <Link to={paths.LIVE_LESSONS}>live class</Link>
    const exploreLink = <Link to={paths.EXPLORE}>explore classes</Link>

    const copyPrefix = 'You’ve completed your week!'
    copy = copyPrefix

    if (hasExplore) {
      copy = (
        <>
          {copyPrefix} Try jumping into {exploreLink}.
        </>
      )
    }

    if (hasLiveLessons) {
      copy = (
        <>
          {copyPrefix} Try jumping into a {liveLessonsLink}.
        </>
      )
    }

    if (hasExplore && hasLiveLessons) {
      copy = (
        <>
          {copyPrefix} Try jumping into a {liveLessonsLink} or {exploreLink}.
        </>
      )
    }
  }

  const hasTitle = hasDaysActive || isGoalExceeded || isWeekComplete

  const goalHeaderCopy = isGoalExceeded
    ? `You beat your goal of`
    : isGoalMet
      ? 'You met your goal of'
      : 'You exercised'

  return (
    <div {...commonProps}>
      <Text color="black" element="header" weight="medium" className={styles.header}>
        <MediaObject
          figure={
            <ButtonWithModal
              icon={<PencilSvg />}
              level="text"
              modal={<GoalAndRemindersModalBox onSuccess={handleEditSuccess} />}
              showModal={showGoalAndRemindersModal}
              className={styles['edit-button']}
              onClick={handleGoalAndRemindersModalButtonClick}>
              <Text className={styles['edit-button--copy']}>Edit goal</Text>
            </ButtonWithModal>
          }
          center
          figurePosition="right"
          className={styles['header--content']}>
          <>
            {goalHeaderCopy}{' '}
            <Text className={styles.tag}>
              {isGoalMet || isGoalExceeded ? `${goal}` : `${daysActive} of ${goal}`}
            </Text>{' '}
            {pluralize('day', goal)} this week
          </>
        </MediaObject>
      </Text>
      <Box color="white">
        <ProgramGoalModal isOpen={showGoalModal} onRequestClose={() => setShowGoalModal(false)} />
        {confetti && (
          <Confetti
            colors={[
              constants.YELLOW_500,
              constants.YELLOW_400,
              constants.YELLOW_300,
              constants.YELLOW_200,
              constants.YELLOW_100,
            ]}
            numberOfPieces={600}
            recycle={false}
            className={styles.confetti}
          />
        )}
        <div className={styles.content}>
          <Row size="xsmall">
            <ProgramGoalWeek highlight={confetti} className={styles.week} />
          </Row>
          <Box color="grey" size="xsmall">
            <Text color="black" element="div">
              {hasTitle && (
                <Row size="xxsmall">
                  <Text element="h3" weight="medium">
                    Great job! 🤩
                  </Text>
                </Row>
              )}
              <Text element="p">{copy}</Text>
              <ButtonWithModal
                level="text"
                modal={<GoalAndRemindersModalBox onSuccess={handleEditSuccess} />}
                className={styles['view-button']}
                onClick={handleGoalAndRemindersModalButtonClick}>
                View weekly goal
              </ButtonWithModal>
            </Text>
          </Box>
        </div>
      </Box>
    </div>
  )
}
