import * as lesson from 'libs/lesson'

// prettier-ignore
export default {
  // prettier-ignore
  instructor: {
    'Alicia Estrada': 'Alicia specializes in strength and conditioning for joint health',
    'Alison Burmeister': 'Alison teaches yoga classes focused on flexibility and fluid movements',
    'Alysha Fyndley': 'Alysha teaches yoga, meditation, and focusing on the mind-body connection',
    'Amanda Rees': 'Amanda focuses on Tai Chi and yoga for balance and stability',
    'Bailey Dietrich': 'Bailey specializes in classes focus on building strength and stability',
    'Chris Litten': 'Chris teaches strength training with a focus on balance and coordination',
    'Glen Baggerly': 'Glen teaches functional fitness focused on strength and posture',
    'Jill&Glen': 'Jill and Glen focus on building strength and stability',
    'Jill Penfold': 'Jill specializes in cardio, strength, and balance work',
    'Jojo Gorchoff': 'Jojo specializes in strength and cardio combination classes',
    'Kerri Campbell': 'Kerri teaches strength and coordination focused pilates classes',
    'Michele LaRocque': 'Michele specializes in sculpt and cardio classes to build strength and endurance',
    'Ryan Bettencourt': 'Ryan’s classes focus on combining strength and cardio exercises',
    'Vanessa Wilkins': 'Vanessa teaches classes incorporating strength and cardio',
  },
  intensity: {
    HIGH: 'High intensity exercises',
    MEDIUM: 'Medium intensity exercises',
    LOW: 'Low intensity exercises',
  },
  // prettier-ignore
  position: {
    [lesson.TYPE_SEATED]: 'Exercises performed while seated for a safe and effective workout',
    [lesson.TYPE_SEATED_STANDING]: 'Exercises performed standing with a sturdy chair nearby for stability',
    [lesson.TYPE_STANDING]: 'Exercises performed standing without getting on and off the floor',
    [lesson.TYPE_STANDING_FLOOR]: 'Exercises both in a standing position and on an exercise mat on the floor',
  },
}
