import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import * as rdd from 'react-device-detect'
import AppDownloadModal from 'app/components/AppDownloadModal'
import ProgramFollowUpModal from 'app/components/ProgramFollowUpModal'
import AssessmentStrengthLowerBodyModals from 'assessments/components/AssessmentStrengthLowerBodyModals'
import Container from 'components/Container'
import Head from 'components/Head'
import Loader from 'components/Loader'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import paths from 'constants/paths'
import type { Lesson } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import EnterpriseOctopusBalanceCheckinModal from 'enterprise/octopus/components/EnterpriseOctopusBalanceCheckinModal'
import EnterpriseOctopusFullDoseCheckinModal from 'enterprise/octopus/components/EnterpriseOctopusFullDoseCheckinModal'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as checkinApiRequest from 'libs/checkin-api-request'
import { useLatestUserCheckinResponses, useUserCheckin } from 'libs/checkin-query'
import navigation from 'libs/navigation'
import {
  useActiveProgramProgress,
  useLessonsForProgram,
  useProgram,
  useProgramCurrentWeek,
} from 'libs/program-query'
import url from 'libs/url'
import user from 'libs/user'
import styles from './Program.module.scss'
import ProgramBlocks from './components/ProgramBlocks'
import ProgramFirstLesson from './components/ProgramFirstLesson'
import ProgramHeader from './components/ProgramHeader'
import ProgramOverview from './components/ProgramOverview'
import ProgramProgressionModals from './components/ProgramProgressionModals'

export default function Program() {
  useEventPageVisited('Program')
  const globalContext = useGlobalContext()
  const { data: programData } = useProgram({
    revalidateOnFocus: false,
  })
  const program = programData?.data
  const exerciseProgramId = program?.exerciseProgram?.id
  const { mutate: mutateProgramLessons } = useLessonsForProgram(exerciseProgramId, {
    dependencies: [programData],
    revalidateOnFocus: false,
  })
  const { mutate: mutateProgramCurrentWeek } = useProgramCurrentWeek()
  const { data: latestUserCheckinResponsesData } = useLatestUserCheckinResponses(null, {
    revalidateOnFocus: false,
  })
  const { data: programProgressData, mutate: mutateProgramProgress } = useActiveProgramProgress()
  const programProgress = programProgressData?.data
  const { data: programFollowUpCheckinData, mutate: mutateProgramFollowUpCheckinData } =
    useUserCheckin(
      {
        type: 'program_follow_up',
        activeOnly: true,
      },
      {
        dependencies: [programData],
        revalidateOnFocus: false,
      }
    )
  const programFollowUpCheckin = programFollowUpCheckinData?.data
  const [enterpriseOctopusFullDoseCheckin, setEnterpriseOctopusFullDoseCheckin] =
    React.useState(null)
  const [lessonOverride, setLessonOverride] = React.useState<Lesson | undefined>()
  const [postLessonState, setPostLessonState] = React.useState<boolean | undefined>()
  const [showEnterpriseOctopusBalanceCheckinModal, setShowEnterpriseOctopusBalanceCheckinModal] =
    React.useState(false)
  const [showEnterpriseOctopusFullDoseCheckinModal, setShowEnterpriseOctopusFullDoseCheckinModal] =
    React.useState(false)
  const [showProgramFollowUpModal, setShowProgramFollowUpModal] = React.useState(false)
  const [showProgramProgressionModal, setShowProgramProgressionModal] = React.useState(false)
  const [showProgramDifficultyAdjustmentCopy, setShowProgramDifficultyAdjustmentCopy] =
    React.useState(false)
  const { feedback } = url.getQueryString()

  async function getEnterpriseOctopusFullDoseCheckin() {
    const response = await checkinApiRequest.getUserCheckin({
      type: 'full_dose',
      activeOnly: true,
    })
    if (response?.data) {
      setEnterpriseOctopusFullDoseCheckin(response.data)
      setShowEnterpriseOctopusFullDoseCheckinModal(true)
    }
  }

  React.useEffect(() => {
    if (programData?.statusCode === 422) {
      navigate(paths.PROGRAM_BUILD, { replace: true })
    }
    mutateProgramProgress()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programData])

  React.useEffect(() => {
    const { checkBalance } = url.getQueryString()

    if (checkBalance) {
      setShowEnterpriseOctopusBalanceCheckinModal(true)
    }

    if (!_.isEmpty(feedback) && programProgressData?.data?.numClassesCompleted === 0) {
      setShowProgramDifficultyAdjustmentCopy(true)
    }
  }, [feedback, program, programProgressData])

  React.useEffect(() => {
    if (
      programFollowUpCheckin &&
      !globalContext.isVideoPlaying &&
      !globalContext.programFollowUpCheckinSubmitted
    ) {
      setShowProgramFollowUpModal(true)
    }
  }, [
    globalContext.isVideoPlaying,
    globalContext.programFollowUpCheckinSubmitted,
    programFollowUpCheckin,
  ])

  React.useEffect(() => {
    if (
      programProgress?.percentComplete === 100 &&
      !globalContext.isVideoPlaying &&
      !user.isEnterpriseOctopusUser(globalContext.user)
    ) {
      setShowProgramProgressionModal(true)
    } else {
      setShowProgramProgressionModal(false)
    }
  }, [globalContext.isVideoPlaying, globalContext.user, programProgress])

  React.useEffect(() => {
    if (user.isEnterpriseOctopusUser(globalContext.user)) {
      const latestUserCheckinResponses = latestUserCheckinResponsesData?.data
      const standWithoutSupportResponse =
        latestUserCheckinResponses?.stand_without_support?.responseValue
      const unsteadyWhenWalkingResponse =
        latestUserCheckinResponses?.unsteady_when_walking?.responseValue

      if (standWithoutSupportResponse === 'no' && !unsteadyWhenWalkingResponse) {
        setShowEnterpriseOctopusBalanceCheckinModal(true)
      }
    }
  }, [globalContext.user, latestUserCheckinResponsesData])

  // this is so sus
  React.useEffect(() => {
    mutateProgramLessons()
    getEnterpriseOctopusFullDoseCheckin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalContext.user?.id, program])

  function handleLessonClick(lesson: any) {
    setLessonOverride(lesson)
    setPostLessonState(false)
    navigation.scrollToTop()
  }

  function handleChangeProgramClose() {
    setShowProgramProgressionModal(false)
  }

  async function handleEnterpriseOctopusFullDoseCheckinModalRequestClose() {
    setShowEnterpriseOctopusFullDoseCheckinModal(false)
  }

  async function handleVideoEnd() {
    setLessonOverride(null)
    await mutateProgramProgress()
    await mutateProgramCurrentWeek()

    setShowProgramDifficultyAdjustmentCopy(false) // hide for feedback
    getEnterpriseOctopusFullDoseCheckin()
  }

  function handlePostLessonStateChange(lessonState: boolean) {
    setPostLessonState(lessonState)
  }

  if (!program) {
    return (
      <View>
        <Loader overlay={false} />
      </View>
    )
  }

  if (rdd.isMobile && !!program.firstLessonId && !program.firstLessonWatchedAt) {
    return <ProgramFirstLesson />
  }

  return (
    <div className={classNames('Program', styles.this)}>
      <Head bodyAttributes={{ class: 'body---has-subheader' }} />
      <AppDownloadModal />
      {!showProgramFollowUpModal && <AssessmentStrengthLowerBodyModals />}
      {showProgramFollowUpModal && (
        <ProgramFollowUpModal
          isOpen={showProgramFollowUpModal}
          programFollowUpCheckin={programFollowUpCheckin}
          onCheckinSuccess={() => {
            setShowProgramFollowUpModal(false)
            mutateProgramFollowUpCheckinData(null, false)
          }}
        />
      )}
      {showProgramProgressionModal && (
        <ProgramProgressionModals
          numClassesCompleted={programProgress.numClassesCompleted}
          program={program}
          onClose={handleChangeProgramClose}
        />
      )}
      {user.isEnterpriseOctopusUser(globalContext.user) && (
        <EnterpriseOctopusBalanceCheckinModal
          isOpen={showEnterpriseOctopusBalanceCheckinModal}
          onSuccess={() => {
            setShowEnterpriseOctopusBalanceCheckinModal(false)
          }}
        />
      )}
      {user.isEnterpriseOctopusUser(globalContext.user) && (
        <EnterpriseOctopusFullDoseCheckinModal
          checkinData={enterpriseOctopusFullDoseCheckin}
          isOpen={showEnterpriseOctopusFullDoseCheckinModal}
          onRequestClose={handleEnterpriseOctopusFullDoseCheckinModalRequestClose}
        />
      )}
      {showProgramDifficultyAdjustmentCopy && (
        <div className={styles.update}>
          <Container size="xxlarge">
            <Text color="black" element="p" flush size="large">
              Hey{' '}
              <Text color="purple" weight="semibold">
                {globalContext.user.firstName}
              </Text>
              {`, we’ve updated your program to be
            ${feedback} challenging, starting with your next class.`}
            </Text>
          </Container>
        </div>
      )}
      <ProgramHeader
        lessonOverride={lessonOverride}
        onLessonClick={handleLessonClick}
        onPostLessonStateChange={handlePostLessonStateChange}
        onVideoEnd={handleVideoEnd}
      />
      <View color="grey" className={styles.view}>
        <Container flush size="xxlarge">
          <Row size="large">
            <div className={styles['overview-and-blocks']}>
              <ProgramOverview className={styles.overview} />
              <ProgramBlocks
                lessonOverride={lessonOverride}
                postLessonState={postLessonState}
                program={program}
                className={styles.blocks}
                onLessonClick={handleLessonClick}
              />
            </div>
          </Row>
          <Text element="p" size="small">
            The Program Content is not intended to and does not provide medical advice. All use of
            equipment shown is optional, and all exercises can be done without any equipment.
          </Text>
        </Container>
      </View>
    </div>
  )
}
