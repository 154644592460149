import classNames from 'classnames'
import _ from 'lodash'
import numberToWords from 'number-to-words'
import React from 'react'
//import Media from 'react-media'
import LessonFavoriteButton from 'app/components/LessonFavoriteButton'
import LessonVideo from 'app/components/LessonVideo'
import ProgramGoal from 'app/components/ProgramGoal'
import Box from 'components/Box'
//import Button, { type ButtonProps } from 'components/Button'
import Button from 'components/Button'
//import ButtonClose from 'components/ButtonClose'
//import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Copy from 'components/Copy'
import Divider from 'components/Divider'
import LessonTrainer from 'components/LessonTrainer'
//import MediaObject from 'components/MediaObject'
import ModalBox from 'components/ModalBox'
import Row from 'components/Row'
import Text from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import View from 'components/View'
import type { Lesson } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import { useUserChallenges } from 'libs/challenge-query'
import { useLessonsForProgram, useProgram } from 'libs/program-query'
//import constants from 'styles/constants.module.scss'
import styles from './ProgramHeader.module.scss'
import ProgramHeaderPostLesson from './components/ProgramHeaderPostLesson'
import recommendationCopyConstants from './constants/recommendation-copy'
import { ReactComponent as ChairSvg } from './images/chair.svg'
import { ReactComponent as PlaySvg } from './images/play.svg'

function getRecommendationIntensityCopy(lessonIntensity?: number) {
  if (!lessonIntensity) return null

  let intensityCopy = recommendationCopyConstants.intensity.MEDIUM

  if (lessonIntensity < 3.5) {
    intensityCopy = recommendationCopyConstants.intensity.LOW
  }
  if (lessonIntensity > 6.5) {
    intensityCopy = recommendationCopyConstants.intensity.HIGH
  }

  return intensityCopy
}

function getRecommendationCopy(lesson: Lesson) {
  const { instructorName } = lesson

  if (!instructorName || !lesson.type) return null

  const trainerCopy =
    recommendationCopyConstants.instructor[instructorName] ||
    `${
      instructorName !== 'Guest Trainer' ? instructorName.split(' ')[0] : 'This instructor'
    } focuses on building strength and stability`
  const positionCopy =
    recommendationCopyConstants.position[lesson.type] ||
    'Exercises designed for a safe and effective workout'

  return [trainerCopy, positionCopy, getRecommendationIntensityCopy(lesson.average_intensity)]
}

type Props = {
  lessonOverride: Lesson | undefined
  // eslint-disable-next-line no-unused-vars
  onLessonClick: (lesson: Lesson) => void
  // eslint-disable-next-line no-unused-vars
  onPostLessonStateChange: (lessonState: boolean) => void
  onVideoEnd: () => Promise<void>
}

export default function ProgramHeader({
  lessonOverride,
  onLessonClick,
  onPostLessonStateChange,
  onVideoEnd,
}: Props) {
  const globalContext = useGlobalContext()
  const { data: programData } = useProgram({ revalidateOnFocus: false })
  const program = programData?.data
  const exerciseProgramId = program?.exerciseProgram?.id
  const { data: programLessonsData, mutate: mutateProgramLessons } = useLessonsForProgram(
    exerciseProgramId,
    {
      dependencies: [programData],
      revalidateOnFocus: false,
    }
  )
  const { mutate: mutateUserProgramChallenges } = useUserChallenges(
    {
      type: 'program',
    },
    {
      revalidateOnFocus: false,
    }
  )
  const [currentLesson, setCurrentLesson] = React.useState<Lesson | undefined>()
  const [lessonIndex, setLessonIndex] = React.useState()
  const [lessonIndexPrevious, setLessonIndexPrevious] = React.useState()
  const [previousLesson, setPreviousLesson] = React.useState<Lesson>()
  const [showPostLesson, setShowPostLesson] = React.useState(false)
  const [showAfterEducationPlayIcon, setShowAfterEducationPlayIcon] = React.useState(false)
  const [showVideoEducationSkipFooter, setShowVideoEducationSkipFooter] = React.useState<
    boolean | undefined
  >()

  const programLessons = programLessonsData?.data

  React.useEffect(() => {
    if (!program || _.isEmpty(programLessons)) return

    if (!_.isEmpty(lessonOverride)) {
      const lessons = programLessonsData?.data
      const overrideId = lessonOverride?.id
      const indexOfOverride = lessons.findIndex((lesson: { id: any }) => lesson.id === overrideId)
      setLessonIndex(indexOfOverride)
      setShowPostLesson(false) // not sure why this stopped working...
    } else {
      let currentIndex = programLessons.findIndex(
        (item: { userView: { status: string } }, index: number) =>
          item.userView?.status !== 'completed' && index !== lessonIndexPrevious
      )
      // if all lessons are completed, just show last class
      if (currentIndex < 0) {
        currentIndex = programLessons.length - 1
      }
      setLessonIndex(currentIndex)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonIndexPrevious, lessonOverride, programLessons])

  React.useEffect(() => {
    mutateProgramLessons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalContext.user?.id, program, previousLesson])

  React.useEffect(() => {
    if (_.isNil(lessonIndex) || !programLessons) return
    setCurrentLesson(programLessons[lessonIndex])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonIndex])

  React.useEffect(() => {
    if (onPostLessonStateChange) onPostLessonStateChange(showPostLesson)
  }, [showPostLesson, onPostLessonStateChange])

  const isEducation = currentLesson?.purpose === 'education'

  function handleVideoProgress(progressSeconds: number) {
    if (!isEducation || progressSeconds < 48 || showVideoEducationSkipFooter === false) {
      return
    }

    setShowVideoEducationSkipFooter(true)
  }

  function updateLessons() {
    const indexOfPlayingLesson = programLessons.findIndex(
      (lesson) => lesson.id === currentLesson.id
    )
    setLessonIndexPrevious(indexOfPlayingLesson)
    setPreviousLesson(programLessons[indexOfPlayingLesson])
  }

  async function handleVideoEnd() {
    if (isEducation) {
      setShowAfterEducationPlayIcon(true)
      setShowVideoEducationSkipFooter(false)
    }

    await onVideoEnd()

    updateLessons()
    //await mutateProgramLessons()
    setShowPostLesson(true)

    if (isEducation) {
      setShowPostLesson(false)
      mutateUserProgramChallenges()
    } else {
      setShowAfterEducationPlayIcon(false)
    }
  }

  function handleNextLessonClick() {
    setShowPostLesson(false)
    mutateProgramLessons()
    mutateUserProgramChallenges()

    if (onLessonClick) onLessonClick(currentLesson) // update tags on videos in ProgramBlock
  }

  if (!currentLesson) return null

  const hasRequiredItems =
    currentLesson.requiredItems && currentLesson.requiredItems?.toLowerCase() !== 'none'

  const isMobile = /android|iphone/i.test(navigator.userAgent)

  const title = (
    <Text element="h1" size="xxlarge" weight="semibold">
      {currentLesson.videoTitle}
    </Text>
  )

  const afterEducationPlayIcon = (
    <Container flush size="xmedium">
      <ModalBox
        size="small"
        title={<Row size="xxxsmall">Let’s get moving, {globalContext.user.firstName}!</Row>}
        subtext={
          <Row size="xxxsmall">
            {lessonIndexPrevious || lessonIndexPrevious === 0
              ? `Congratulations, you finished the ${numberToWords.toWordsOrdinal(
                  lessonIndexPrevious + 1
                )} class in your program!
              Now, it’s time to move. ${
                lessonIndexPrevious <= 1 ? 'Start ' : 'Continue '
              }your exercise program today.`
              : 'Congratulations on your progress so far! Now, it’s time to move.'}
          </Row>
        }>
        <Button color="yellow" icon={<PlaySvg />} size="xlarge">
          Start class
        </Button>
      </ModalBox>
    </Container>
  )

  //const videoSkipFooterCopy = (
  //  <Text color="black" element="p" flush weight="medium">
  //    Ready to jump to your first exercise class?
  //  </Text>
  //)

  const recommendationCopy = getRecommendationCopy(currentLesson)

  //const videoSkipFooterButtonProps: Partial<ButtonProps> = {
  //  children: 'Go to next class',
  //  color: 'yellow',
  //  icon: 'arrow-right',
  //  onClick: () => {
  //    setShowVideoEducationSkipFooter(false)
  //    updateLessons()
  //    globalContext.analytics?.trackEvent('Lesson skip')
  //  },
  //}

  //const videoSkipFooterCloseButtonProps: Partial<ButtonProps> = {
  //  onClick: () => setShowVideoEducationSkipFooter(false),
  //}

  return (
    <header className="ProgramHeader">
      <View>
        {showPostLesson ? (
          <ProgramHeaderPostLesson
            data={previousLesson}
            lessonNext={currentLesson}
            onLessonNextClick={handleNextLessonClick}
          />
        ) : (
          <div className={styles['video-and-sidebar']}>
            <div className={classNames(styles['video-wrap'])}>
              <LessonVideo
                customPlayIcon={showAfterEducationPlayIcon ? afterEducationPlayIcon : undefined}
                lesson={currentLesson}
                thumbnail={currentLesson.videoThumbnail}
                url={currentLesson.videoLink}
                userExerciseProgramId={program.id}
                className={styles.video}
                onEnd={handleVideoEnd}
                onProgress={handleVideoProgress}
              />
              {/*isEducation && (
                <footer
                  className={classNames(
                    styles['video-skip-footer'],
                    showVideoEducationSkipFooter && styles['video-skip-footer---visible']
                  )}>
                  <Media query={{ minWidth: constants.GT_MOBILE_MEDIUM }}>
                    <MediaObject
                      figure={<ButtonClose {...videoSkipFooterCloseButtonProps} />}
                      center
                      figurePosition="right">
                      <MediaObject
                        figure={<Button {...videoSkipFooterButtonProps} size="xsmall" />}
                        center
                        figurePosition="right"
                        inline
                        size="xsmall">
                        {videoSkipFooterCopy}
                      </MediaObject>
                    </MediaObject>
                  </Media>
                  <Media query={{ maxWidth: constants.MOBILE_MEDIUM }}>
                    <Text align="center">
                      <Buttons axis="y" size="small">
                        {videoSkipFooterCopy}
                        <Button {...videoSkipFooterButtonProps} full />
                        <Button {...videoSkipFooterCloseButtonProps} level="text">
                          Close
                        </Button>
                      </Buttons>
                    </Text>
                  </Media>
                </footer>
              )*/}
            </div>
            <Text element="div" className={styles.sidebar}>
              <div>
                <Row size="xsmall">{title}</Row>
                <Row size="smallx">
                  <LessonTrainer data={currentLesson} size="large" weight="normal" />
                </Row>
                <Row size="small">
                  <LessonFavoriteButton lesson={currentLesson} lessonId={currentLesson.id} />
                </Row>
                <Row size="xmedium">
                  <Row size="small">
                    {recommendationCopy && !isEducation ? (
                      <Box color="grey" size="small">
                        <Text element="h2" weight="medium">
                          Why we chose this class for you
                        </Text>
                        <Divider thin size="xsmall" />
                        <Copy>
                          <Text element="ul">
                            {recommendationCopy.filter(Boolean).map((item) => (
                              <Row element="li" key={item} size="xsmall">
                                {item}
                              </Row>
                            ))}
                          </Text>
                        </Copy>
                      </Box>
                    ) : (
                      <>
                        <Row size="xxxsmall">
                          <Text element="h2" weight="medium">
                            About class
                          </Text>
                        </Row>
                        <Text element="p" flush>
                          {currentLesson.exerciseBlocks?.exerciseBlockLesson
                            ?.blockLessonDescription || currentLesson.instructions}
                        </Text>
                      </>
                    )}
                  </Row>
                  {hasRequiredItems && !recommendationCopy && (
                    <>
                      <Row size="xxxsmall">
                        <Text element="h2" weight="medium">
                          Equipment
                        </Text>
                      </Row>
                      <Copy>
                        <TextWithIcon
                          icon={
                            currentLesson.requiredItems?.toLowerCase().includes('chair') ? (
                              <ChairSvg />
                            ) : undefined
                          }
                          inline>
                          {currentLesson.requiredItems?.replace(/,/g, ', ')}
                        </TextWithIcon>
                      </Copy>
                    </>
                  )}
                </Row>
                <Row flush={!isMobile} size="xmedium">
                  <ProgramGoal />
                </Row>
              </div>
            </Text>
          </div>
        )}
      </View>
    </header>
  )
}
