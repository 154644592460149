import classNames from 'classnames'
import React from 'react'
import Tags, { type TagsProps } from 'components/Tags'
import type { Lesson } from 'constants/types'
import * as lesson from 'libs/lesson'

type Props = {
  data: Lesson
} & Omit<TagsProps, 'data'>

export default function LessonTags({ children, className, data, ...props }: Props) {
  return (
    <Tags
      {...props}
      color="black"
      data={lesson.getTags(data)}
      className={classNames('LessonTags', className)}
    />
  )
}
